import { Router } from './router/routes';
import {BrowserRouter,HashRouter} from "react-router-dom";

function App() {
  return (
    <div style={{height: '100%'}}>
      <HashRouter>
        <Router />
      </HashRouter>
    </div>
  );
}

export default App;
