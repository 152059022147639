import { Suspense, lazy } from 'react';
import { useRoutes, Navigate } from 'react-router-dom';


const routes = [
  {
    path: '/web/*',
    auth: false,
    component: lazy(()=>import('./layout')),
    children: [
      {
        path: 'home',
        auth: false,
        component: lazy(()=>import('@/pages/home')),
      },
      {
        path: 'case_show/*',
        auth: false,
        component: lazy(()=>import('@/pages/case_show')),
        children: [
          {
            path: 'home',
            auth: false,
            component: lazy(()=>import('@/components/case_show/case-show-home-comp')),
          },
          {
            path: 'mars_man',
            auth: false,
            component: lazy(()=>import('@/components/case_show/mars_man')),
          },
          {
            path: 'lingjiao',
            auth: false,
            component: lazy(()=>import('@/components/case_show/lingjiao')),
          },
          {
            path: 'zeqiao_doctor',
            auth: false,
            component: lazy(()=>import('@/components/case_show/zeqiao_doctor')),
          },
          {
            path: 'hongyue',
            auth: false,
            component: lazy(()=>import('@/components/case_show/hongyue')),
          },
          {
            path: 'kaLife',
            auth: false,
            component: lazy(()=>import('@/components/case_show/kalife')),
          },
          {
            path: '*',
            auth: false,
            component: lazy(()=>import('@/components/case_show/case-show-home-comp')),
          },
        ]
      },
      {
        path: 'product_service/*',
        auth: false,
        component: lazy(()=>import('@/pages/product_service')),
      },
      {
        path: 'about_us/*',
        auth: false,
        component: lazy(()=>import('@/pages/about_us')),
      },
      {
        path: 'dev_tools/*',
        auth: false,
        component: lazy(()=>import('@/pages/dev_tools')),
        children: [
          {
            path: 'ui_survey',
            auth: false,
            component: lazy(()=>import('@/components/dev_tools/ui_survey')),
          },
          {
            path: '*',
            auth: false,
            component: lazy(()=>import('@/components/dev_tools/ui_survey')),
          },
        ]
      },
      {
        path: '*',
        auth: false,
        component: lazy(()=>import('@/pages/home')),
      }
    ]
  },
  {
    path: '*',
    auth: false,
    component: lazy(()=>import('./layout')),
    children: [
      {
        path: '*',
        auth: false,
        component: lazy(()=>import('@/pages/home')),
      }
    ]
  },
];

const checkAuth = (routers,path)=>{
  for(const data of routers) {
    if (data.path == path) {
      return data;
    }

    if (data.children) {
      const res = checkAuth(data.children, path);

      if (res) {
        return res;
      }
    }
  }

  return null;
}

const generateRouter = (routers) => {
  return routers.map((item,index) => {
    if (item.children) {
      item.children = generateRouter(item.children);
    }

    item.element = <Suspense fallback={
      <div>加载中...</div>
    }>
      <item.component />
    </Suspense>
    
    return item;
  })
}

const Router = () => useRoutes(generateRouter(routes));
const checkRouterAuth = (path) => {
  let auth = null;
  auth = checkAuth(routes,path);
  return auth;
}

export { Router, checkRouterAuth };